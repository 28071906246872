import React, {useState, useRef}  from 'react';
import { Link } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import Section from "./Section";
import breakpoints from '../assets/styles/breakpoints';

//svgs
import chevron from '../assets/images/chevron.svg';
import ArrowDown from "../assets/icons/arrow-down.svg";

const Styles = styled.div`
    .copy-image-block {
        padding: 0 1.25rem;
    }

    .container {
        display: inline-block;
    }

    .left {
        display: flex;
        flex-direction: column;

        .image-container {
            width: 100%;
            margin: 0 0 1rem;
        }
    }

    .copy-container {

        & > div {

            *:last-child {
                ${props => !props.showMobileAccordion && `*:last-child { margin: 0; }`}
                @media(min-width: ${breakpoints.md}) {
                    ${props => !props.showDesktopAccordion && `*:last-child { margin: 0; }`}
                }
            }
        }

        h1, h2, h3, h4, h5, h6, p, ul, ol {
            margin: 0 0 1rem;
        }

        a {
            color: black;
        }

        ul, ol {
            padding-inline-start: 40px;
        }

        .link {
            margin: 1rem 0 0;
            display: inline-block;
            color: var(--primary);
            text-decoration: none;
            font-weight: 700;

            p {

            position: relative;
            padding-right: 1.6rem;

                span {
                    position: absolute;
                    right: 0;
                    height: 20px;
                    width: 20px;
                    background: var(--primary) url(${chevron}) center no-repeat;
                    border-radius: 100%;
                }

            }

        }
    }

    .image-container {

        margin: 1rem 0 0 0;

        .gatsby-image-wrapper {
            width: 100%!important;
            height: 22.625rem!important;
        }

        img {
            border-radius: .313rem;
        }
    }


    @media only screen and (min-width: 1024px) {
        .copy-image-block {
            padding: 0;
        }

        .container {
            display: inline-block;
            margin-left: 50%;
            transform: translate(-50%);
        }

        .col-10 {
            display: flex;
            position: relative;

            &.left {

                flex-direction: row;

                .image-container {
                    width: 55%;
                    margin: 0;
                }

                .copy-container {
                    order: 2;
                    margin: auto 0 auto 5%;
                }
            }
        }

        .left .copy-container {
            order: 2;
        }

        .copy-container {
            width: 40%;
            height: fit-content;
            margin: auto 5% auto 0;

            &.full-width {
                width: 100%;
                margin: 0;
            }
        }

        .image-container {
            width: 55%;
            min-height: 36.25rem;
            margin: 0;

            .gatsby-image-wrapper {
                height: 100%!important;
            }

            img {
                
            }

        }
    }
   
`;


const ContentWrapper = styled.div`
  height: ${props => props.showMobileAccordion ? "12rem" : "auto"};
  overflow: hidden;
  position: relative;
  @media(min-width: ${breakpoints.md}) {
    height: ${props => props.showDesktopAccordion ? "12rem" : "auto"};
  }
`;

const BlockWrapper = styled.div`
  max-height: ${props => props.showMobileAccordion ? "9rem" : "none"};
  overflow: hidden;
  position: relative;
  &:after{
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 4.5rem;
    background: linear-gradient(0deg, rgb(248, 248, 248) 0%, transparent 100%);
    opacity: 1;
    display: ${props => props.showMobileAccordion ? "block" : "none"};
  @media only screen and (min-width: ${breakpoints.md}) {
    display: ${props => props.showDesktopAccordion ? "block": "none"};
  }
  }
  @media only screen and (min-width: ${breakpoints.md}) {
    max-height: ${props => props.showDesktopAccordion ? "8.5rem" : "none"};
    }
`;

const ReadMore = styled.div`
    position: absolute;
    bottom: 1.5rem;
    cursor: pointer;
    display: ${props => props.showMobileAccordion ? "block" : "none"};
    @media(min-width: ${breakpoints.md}) {
      display: ${props => props.showDesktopAccordion ? "block": "none"};
    }
    img {
      width: 2rem;
      height: 2rem;
      position: absolute;
      bottom: -0.375rem;
      left: 5.75rem;
    }
`;

const ReadLess = styled.div`
    cursor: pointer;
    display: block;
    position: relative;
    margin-top: 2.5rem;
    margin-bottom: 1.25rem;
    img {
      width: 2rem;
      height: 2rem;
      position: absolute;
      bottom: -0.45rem;
      left: 5.125rem;
      transform: rotate(180deg);
    }
`;

const CopyImageBlock = (props) => {
    const [showMobileAccordion, setShowMobileAccordion] = useState(props.showBothAccordion || props.showMobileAccordion) 
    const [showDesktopAccordion, setShowDesktopAccordion] = useState((props.showBothAccordion || props.showDesktopAccordion) && !props.image)
    const [showReadLess, setShowReadLess] = useState(false)

    const copyRef = useRef(null);

    const portableTextContent = props._rawCopy;
    const serializers = {
        marks: {
            link: ({mark, children}) => {
                const {href} = mark;
                return <a href={href} target="_blank" rel="noopener">{children}</a>
            },
        }
    };

    const onClickAccordion = () => {
        setShowMobileAccordion(!showMobileAccordion)
        setShowDesktopAccordion(!showDesktopAccordion)
        setShowReadLess(true)
      }
  
    const onClickReadLess = () => {
        if (copyRef.current) {
          copyRef.current.scrollIntoView({
            behavior: 'smooth'})
        }
  
        setShowMobileAccordion(true);
        setShowDesktopAccordion(true);
        setShowReadLess(false);
  
    }
    
    return (
        <Section id={ props.id ? props.id : null }>
            <Styles showMobileAccordion={showMobileAccordion} showDesktopAccordion={showDesktopAccordion}>
                <section className="copy-image-block">
                    <div className="container">
                        <div className="col-1 hidden-m"/>
                        <div className={`col-10 ${props.left ? "left" : ""}`}>
                            <div className={`copy-container ${!props.image ? "full-width" : ""}`} >
                            <ContentWrapper ref={copyRef} showMobileAccordion={showMobileAccordion} showDesktopAccordion={showDesktopAccordion}>
                            <BlockWrapper showMobileAccordion={showMobileAccordion} showDesktopAccordion={showDesktopAccordion}>
                                <BlockContent blocks={portableTextContent} serializers={serializers}/>
                                {(props.linkUrl && props.link) ? (
                                  <Link className="link" to={props.linkUrl}>
                                      <p>
                                          {props.link}
                                          <span/>
                                      </p>
                                  </Link>
                                ) : null}
                                {showReadLess && <ReadLess onClick={onClickReadLess}>
                                    Read less
                                    <img src={ArrowDown} />
                                </ReadLess>}
                          </BlockWrapper>
                          <ReadMore onClick={onClickAccordion} showMobileAccordion={showMobileAccordion} showDesktopAccordion={showDesktopAccordion}>
                            Read more
                            <img src={ArrowDown} />
                            </ReadMore>
                          </ContentWrapper>
                            </div>
                            {props.image && (
                                <div className="image-container">
                                    <Img fixed={props.image ? props?.image?.asset?.fixed: ""}/>
                                </div>
                              )}
                        </div>
                    </div>
                </section>
            </Styles>
        </Section>
    );
};

export default CopyImageBlock;